import { ReactComponent as WarningIcon } from "assets/images/field-error.svg";
import { ReactComponent as FindInPage } from "assets/images/find-in-page.svg";
import CustomLoader from "components/common/CustomLoader";
import CustomToast from "components/CustomToast";
import { useOrder } from "context/order/OrderContext";
import { handleDirectCardLevelData } from "components/category/accordionUtils";
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/customHooks";
import { handleSearchQueryReset } from "store/globalSearchAndFilters/globalSearchAndFiltersSlice";
import { useGetSearchResultsByBranchIdQuery } from "store/homePage/homePageAPISlice";
import { IObject } from "types";

const SearchPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { searchQuery } = useAppSelector(
    (state) => state.globalSearchAndFilters
  );
  const initialAPISuccess = useAppSelector(
    (state) => state.auth.initialAPISuccess
  );
  const ids = sessionStorage.getItem("Ids");
  const parsedIds = ids && JSON.parse(ids);

  const [categorizedSearchData, setCategorySearchData] = useState<any[] | null>(
    null
  );
  const [toastMessage, setToastMessage] = useState<string | null>(null);

  const {
    data: searchResultData,
    isLoading: searchResultIsLoading,
    isError: searchResultIsError,
    isSuccess: searchResultIsSuccess,
  } = useGetSearchResultsByBranchIdQuery(
    {
      branchId: parsedIds?.branchId,
      searchText: searchQuery,
    },
    { skip: !(initialAPISuccess && parsedIds?.branchId && searchQuery.trim()) }
  );

  useEffect(() => {
    if (!searchQuery.trim().length) {
      setCategorySearchData(null);
    }
  }, [searchQuery]);

  const { orderData, orderIsLoading, orderIsFetching } = useOrder();

  const transformData = (arr: IObject[]) => {
    const result: IObject[] = [];
    arr.forEach((item) => {
      let categoryGroup = result.find(
        (group) => group.categoryName === item.topLevelCategory
      );
      if (!categoryGroup) {
        categoryGroup = {
          id: Math.floor(Math.random() * 1_000_000),
          categoryName: item.topLevelCategory,
          menuResponseDetails: [],
        };
        result.push(categoryGroup);
      }

      categoryGroup.menuResponseDetails.push(item);
    });
    const finalResult = result.map((categorizedData: any) => ({
      ...categorizedData,
      menuResponseDetails: (
        <div className="promotionDetailsPage">
          {handleDirectCardLevelData([categorizedData], orderData)}
        </div>
      ),
    }));

    return finalResult;
  };

  useEffect(() => {
    if (searchResultIsSuccess && searchResultData && searchResultData.length) {
      const transformedData = transformData(searchResultData);
      setCategorySearchData(transformedData);
    } else {
      setCategorySearchData(null);
    }
  }, [searchResultIsSuccess, searchResultData, orderData]);

  useEffect(() => {
    if (searchResultIsError) {
      setCategorySearchData(null);
      setToastMessage(
        "Oops! There was an error while fetching the data. Please try again."
      );
      dispatch(handleSearchQueryReset());
    }
  }, [dispatch, searchResultIsError]);

  const handleOnToastDismiss = () => setToastMessage(null);

  const renderResults = () => {
    if (!searchQuery.trim().length) {
      return (
        <div className="flex flex-col items-center justify-center h-full searchPage">
          <FindInPage className="iconColor" />
          <div className="noSearchQuery text-center mt-[25px]">
            <div>Enter text to search across</div>
            <div>all menu items</div>
          </div>
        </div>
      );
    } else if (searchResultIsSuccess) {
      return categorizedSearchData &&
        categorizedSearchData.length &&
        categorizedSearchData !== null ? (
        <div className="px-4 searchPage">
          {categorizedSearchData.map((searchData) => (
            <div key={searchData?.id} className="my-5">
              <div className="categoryName mb-[10px]">
                {searchData?.categoryName}
              </div>
              <div>{searchData?.menuResponseDetails}</div>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center h-full pageInfoMessage searchPage">
          <div className="warningIcon iconColor">
            <WarningIcon className="mb-[10px]" />
          </div>
          <div>Oops! No results found.</div>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {(orderIsLoading || orderIsFetching || searchResultIsLoading) && (
        <CustomLoader isLoading />
      )}
      {renderResults()}
      {toastMessage && (
        <CustomToast
          message={toastMessage}
          handleOnToastDismiss={handleOnToastDismiss}
        />
      )}
    </>
  );
};

export default SearchPage;

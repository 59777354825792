import { Affix } from "antd";
import { homePageTabItems, orderTabItems } from "central-utils/tabUtils";
import CustomTab from "components/CustomTab";
import DefaultHeaderContent from "components/header/DefaultHeaderContent";
import SearchBarHeader from "components/header/SearchBarHeader";
import React, { useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { useAppSelector } from "store/customHooks";

const CustomHeader: React.FC = () => {
  const location = useLocation();
  const { pathname } = location;

  const { disableConfirmedTab } = useAppSelector((state) => state.header);
  const { isOrderTimeClosed } = useAppSelector(
    (state) => state.tableStatusAndOrder
  );

  const { headerLogo, altName } = useAppSelector((state) => state.header);

  // Tab Manipulation based on the Confirm Tab Disable State
  const updatedOrderPageTab = useMemo(
    () =>
      orderTabItems.map((item) => {
        if (item.label === "Completed") {
          return {
            ...item,
            disabled: disableConfirmedTab,
          };
        } else if (item.label === "Pending") {
          return {
            ...item,
            disabled: isOrderTimeClosed,
          };
        }
        return item;
      }),
    [disableConfirmedTab, isOrderTimeClosed]
  );

  useEffect(() => {
    if (isOrderTimeClosed) {
      sessionStorage.setItem(
        "activeOrderHeaderTab",
        JSON.stringify(updatedOrderPageTab[1].id)
      );
    }
  }, [isOrderTimeClosed, updatedOrderPageTab]);

  // Paths where the header should not be displayed
  const pathsWithoutHeader: string[] = [];

  // If the current path is in the pathsWithoutHeader array, do not render the header
  if (pathsWithoutHeader.includes(pathname)) {
    return null;
  }

  const handleHeaderContent = () => {
    switch (pathname) {
      case "/":
      case "/check-out":
      case "/customer-details":
      case "/verify-otp":
      case "/enter-name":
      case "/verify-pin":
      case "/payment-confirmation":
      case "/payment-in-progress-block":
      case "/payment-status":
      case "/terms/conditions":
      case "/terms/service":
      case "/bill-pending":
      case "/session-expired":
        return (
          <div className="flex justify-center relative items-center plainIconHeader">
            {headerLogo && headerLogo?.length && (
              <img alt={altName || ""} src={headerLogo} />
            )}
          </div>
        );

      case "/home":
        return (
          <div>
            <DefaultHeaderContent />
            <Affix offsetTop={0}>
              <CustomTab
                identifierKey="homePageHeaderTabs"
                tabItems={homePageTabItems}
                defaultTab={sessionStorage?.getItem("activeHomeHeaderTab")}
              />
            </Affix>
          </div>
        );

      case "/order":
        return (
          <div>
            <DefaultHeaderContent />
            <Affix offsetTop={0}>
              <CustomTab
                identifierKey="orderPageHeaderTabs"
                tabItems={updatedOrderPageTab}
                defaultTab={sessionStorage?.getItem("activeOrderHeaderTab")}
              />
            </Affix>
          </div>
        );

      case "/search":
        return (
          <Affix offsetTop={0}>
            <SearchBarHeader />
          </Affix>
        );

      default:
        return (
          <Affix offsetTop={0}>
            <DefaultHeaderContent />
          </Affix>
        );
    }
  };

  return handleHeaderContent();
};

export default CustomHeader;

import { Layout as AntdLayout, Flex } from "antd";
import CustomFooter from "components/footer/CustomFooter";
import CustomHeader from "components/header/CustomHeader";
import { useOrder } from "context/order/OrderContext";
import React, { ReactNode, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

interface Props {
  children: ReactNode;
}

const MainLayout: React.FC<Props> = ({ children }) => {
  const { orderData, orderIsUninitialized } = useOrder();

  const { pathname } = useLocation();
  const { Content } = AntdLayout;

  // Paths where the footer should not be displayed
  const pathsWithoutFooter = [
    "/",
    "/customer-details",
    "/payment-confirmation",
    "/payment-in-progress-block",
    "/payment-status",
    "/search",
    "/terms/service",
    "/terms/conditions",
    "/check-out",
    "/bill-pending",
    "/session-expired"
  ];

  // Define header heights for different paths
  const getHeaderHeight = () => {
    if (pathsWithoutFooter.includes(pathname)) {
      return 100; // Header height for paths without footer
    } else if (["/home", "/order"].includes(pathname)) {
      return 132; // Example: Another header size for a specific path
    } else if (["/search"].includes(pathname)) {
      return 80; // Example: Another header size for a specific path
    } else {
      return 50; // Default header size
    }
  };

  const getFooterHeight = () => {
    if (
      (pathname === "/order" &&
        !orderIsUninitialized &&
        orderData &&
        !orderData?.isVerified) ||
      (pathname === "/re-order" &&
        orderData &&
        orderData.orderGroups &&
        orderData.orderGroups.length)
    ) {
      return 160;
    } else {
      return 70;
    }
  };

  const [headerHeight, setHeaderHeight] = useState(getHeaderHeight());
  const [footerHeight, setFooterHeight] = useState(getFooterHeight());

  useEffect(() => {
    // Update header and footer height if pathname changes
    setHeaderHeight(getHeaderHeight());
    setFooterHeight(getFooterHeight());
  }, [pathname]);

  return (
    <Flex
      style={{ overflowY: "hidden" }}
      className="relative"
      gap={"middle"}
      wrap
    >
      <AntdLayout className="min-h-[100dvh] max-w-[100vw]">
        <CustomHeader />
        <Content
          className="px-4 mainContentBackground flex flex-col overflow-y-auto"
          style={{
            minHeight: `calc(100dvh - ${headerHeight}px - ${footerHeight}px)`, // Use 100vh instead of 100dvh for better compatibility
            paddingBottom: pathsWithoutFooter.includes(pathname)
              ? 0
              : footerHeight, // Ensures footer doesn't overlap content
          }}
        >
          {children}
        </Content>
        <CustomFooter />
      </AntdLayout>
    </Flex>
  );
};

export default MainLayout;

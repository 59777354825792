import { ReactComponent as WarningIcon } from "assets/images/field-error.svg";
import CustomLoader from "components/common/CustomLoader";
import CustomToast from "components/CustomToast";
import ForYouTabContent from "components/home-page-tabs/ForYouTabContent";
import MenuListingTabContent from "components/home-page-tabs/MenuListingTabContent";
import { usePriceChannel } from "context/pusher/price";
import usePreventBackNavigation from "hooks/usePreventBackNavigation";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/customHooks";
import { handleTabTypeInitialAccordionState } from "store/globalSearchAndFilters/globalSearchAndFiltersSlice";
import { useGetGroupPromotionByBranchIdQuery } from "store/homePage/homePageAPISlice";
import { useGetStoreDataByBranchIdQuery } from "store/initialAPI/initialAPISlice";
import { useGetCategorizedMenuItemsQuery } from "store/menuCustomization/menuCustomizationAPISlice";
import { IObject } from "types";

const HomePage: React.FC = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();

  const pusherPriceData = usePriceChannel();

  const ids = sessionStorage.getItem("Ids");
  const parsedIds = ids && JSON.parse(ids);

  const initialAPISuccess = useAppSelector(
    (state) => state.auth.initialAPISuccess
  );
  const tabContent = useAppSelector(
    (state) => state.tab.selectedTabDetails.homePageHeaderTabs
  );
  const {
    eggetarianFilter,
    nonVegetarianFilter,
    vegetarianFilter,
    alcoholicFilter,
    nonAlcoholicFilter,
  } = useAppSelector((state) => state.globalSearchAndFilters);

  const {
    drinkTabAccordionState,
    eatTabAccordionState,
    smokeTabAccordionState,
  } = useAppSelector((state) => state.globalSearchAndFilters);

  const [promotionTabData, setPromotionTabData] = useState<IObject[] | null>(
    null
  );
  const [categoryTabData, setCategoryTabData] = useState<IObject | null>(null);
  const [floatingButtonData, setFloatingButtonData] = useState<any>([]);
  const [updateMenuItemPrice, setUpdateMenuItemPrice] =
    useState<boolean>(false);
  const [preventBackwardNavigation, setPreventBackwardNavigation] =
    useState<boolean>(false);

  const {
    data: groupedPromotionData,
    isLoading: groupedPromotionIsLoading,
    isError: groupedPromotionIsError,
    isSuccess: groupedPromotionIsSuccess,
    isFetching: groupedPromotionIsFetching,
  } = useGetGroupPromotionByBranchIdQuery(
    {
      branchId: parsedIds?.branchId,
    },
    { skip: !initialAPISuccess }
  );

  const { data: generalConfigData, isSuccess: generalConfigIsSuccess } =
    useGetStoreDataByBranchIdQuery(
      {
        branchId: Number(parsedIds?.branchId),
      },
      { skip: !initialAPISuccess }
    );

  useEffect(() => {
    if (generalConfigIsSuccess && generalConfigData) {
      sessionStorage.setItem(
        "closingTime",
        JSON.stringify(generalConfigData.generalSettings[0].orderClosingTime)
      );
      sessionStorage.setItem(
        "openingTime",
        JSON.stringify(generalConfigData.generalSettings[0].orderOpeningTime)
      );
    }
  }, [generalConfigData, generalConfigIsSuccess, dispatch]);

  const {
    data: categorizedMenuData,
    isLoading: categorizedMenuIsLoading,
    isError: categorizedMenuIsError,
    isSuccess: categorizedMenuIsSuccess,
    isFetching: categorizedMenuIsFetching,
    refetch: refetchCategorizedMenuData,
  } = useGetCategorizedMenuItemsQuery(
    {
      branchId: parsedIds?.branchId,
      vegFilter: vegetarianFilter,
      nonVegFilter: nonVegetarianFilter,
      eggFilter: eggetarianFilter,
      alcoholicFilter: alcoholicFilter,
      nonAlcoholicFilter: nonAlcoholicFilter,
    },
    { skip: !initialAPISuccess }
  );

  //format floating menu button data
  const handleFloatingButtonData = ([apiData]: any) => {
    if (apiData?.subCategories?.length === 0) {
      return [];
    }
    const secondLevelData = apiData?.subCategories?.map((secondLevel: any) => ({
      name: secondLevel?.categoryName,
      helperData: secondLevel,
    }));
    return secondLevelData;
  };

  // Formatted Promotions Data
  useEffect(() => {
    if (
      groupedPromotionIsSuccess &&
      groupedPromotionData?.data &&
      groupedPromotionData?.data?.length
    ) {
      // Filter promotion data based on the active and deleteStatus condition
      const filteredPromotionData = groupedPromotionData?.data?.filter(
        (promotionData: any) =>
          promotionData?.active &&
          (promotionData?.deleteStatus === false ||
            promotionData?.deleteStatus === null)
      );

      filteredPromotionData && filteredPromotionData.length
        ? setPromotionTabData(filteredPromotionData)
        : setPromotionTabData(null);
    }
  }, [groupedPromotionData, groupedPromotionIsSuccess]);

  // Formatted Category Data
  useEffect(() => {
    if (
      categorizedMenuIsSuccess &&
      categorizedMenuData &&
      categorizedMenuData.length
    ) {
      const foundCategory = categorizedMenuData.find(
        (item: any) =>
          item?.categoryName?.toLowerCase()?.trim() ===
          tabContent?.label?.toLowerCase()?.trim()
      );
      foundCategory
        ? setCategoryTabData(foundCategory)
        : setCategoryTabData(null);
      setFloatingButtonData(handleFloatingButtonData([foundCategory]));
    } else {
      setCategoryTabData(null);
    }
  }, [categorizedMenuData, categorizedMenuIsSuccess, tabContent]);

  useEffect(() => {
    if (categoryTabData) {
      const tabValue = tabContent?.label?.trim().toUpperCase();
      if (
        categoryTabData?.subCategories?.length &&
        ((tabValue === "DRINK" && !drinkTabAccordionState?.length) ||
          (tabValue === "EAT" && !eatTabAccordionState?.length) ||
          (tabValue === "SMOKE" && !smokeTabAccordionState.length))
      ) {
        dispatch(
          handleTabTypeInitialAccordionState({
            accordionStateArr: categoryTabData?.subCategories?.map(() => true),
            tabType: tabValue,
          })
        );
      }
    }
  }, [categoryTabData]);

  // Render error messages if any API call fails
  const renderErrorContents = (err: boolean) => {
    if (err) {
      return (
        <div className="flex flex-col items-center justify-center h-full pageInfoMessage">
          <div className="warningIcon iconColor">
            <WarningIcon className="mb-[10px]" />
          </div>
          <div>Oops! There was an error. Please try again.</div>
        </div>
      );
    }
    return null;
  };

  const renderMenuTabContents = () => {
    return (
      <>
        {(categorizedMenuIsLoading || categorizedMenuIsFetching) && (
          <CustomLoader isLoading />
        )}
        {renderErrorContents(categorizedMenuIsError)}
        {categorizedMenuIsSuccess && (
          <MenuListingTabContent
            categoryTabData={categoryTabData || []}
            floatingButtonData={floatingButtonData}
          />
        )}
      </>
    );
  };

  const renderForYouTabContents = () => {
    return (
      <>
        {(groupedPromotionIsFetching || groupedPromotionIsLoading) && (
          <CustomLoader isLoading />
        )}
        {renderErrorContents(groupedPromotionIsError)}
        {groupedPromotionIsSuccess && (
          <ForYouTabContent promotionData={promotionTabData || []} />
        )}
      </>
    );
  };

  // Render Tab contents based on selected tab
  const renderTabContents = () => {
    if (tabContent) {
      switch (tabContent.label) {
        case "For You":
          return renderForYouTabContents();
        case "Drink":
          return renderMenuTabContents();
        case "Eat":
          return renderMenuTabContents();
        case "Smoke":
          return renderMenuTabContents();
      }
    }
    return null;
  };

  // Pusher Price handling for dynamic price updates
  useEffect(() => {
    if (
      pusherPriceData &&
      Object.keys(pusherPriceData ?? {}).length &&
      Object.hasOwn(pusherPriceData, "isPriceUpdateFlag")
    ) {
      setUpdateMenuItemPrice(pusherPriceData.isPriceUpdateFlag);

      // Reset after few seconds
      const timer = setTimeout(() => {
        setUpdateMenuItemPrice(false);
      }, 2500);

      return () => clearTimeout(timer);
    }
  }, [pusherPriceData]);

  // Prevent back navigation logic
  useEffect(() => {
    if (location.state && Object.keys(location.state)?.length > 0) {
      setPreventBackwardNavigation(true);
    } else {
      setPreventBackwardNavigation(false);
    }
  }, [location]);

  usePreventBackNavigation(preventBackwardNavigation ? "/home" : "");

  return (
    <>
      {/* Banner when there's change in Price */}
      {updateMenuItemPrice && (
        <CustomToast message="Prices have been changed!" duration={5000} />
      )}
      {renderTabContents()}
    </>
  );
};

export default HomePage;

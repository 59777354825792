import { ReactComponent as MenuItemInfo } from "assets/images/menu-item-info.svg";
import { rupeeFormatter } from "central-utils/currencyUtils";
import { convertToCapitalizedString } from "central-utils/stringUtils";
import CounterButton from "components/CounterButton";
import CustomToast from "components/CustomToast";
import MenuItemCustomizationModal from "components/menu-item-customization-modal";
import RepeatCustomizationModal from "components/RepeatCustomizationModal";
import { useCartChannel } from "context/pusher/cart";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/customHooks";
import {
  useAddMenuItemToOrderByOrderIdMutation,
  useUpdateOrderItemQuantityByProductIdMutation,
} from "store/orders/ordersAPISlice";
import { handleCounterLoader } from "store/tab/tabSlice";

interface Props {
  cardDetails: {
    foodType?: "VEGETARIAN" | "NON_VEGETARIAN" | "EGGETARIAN";
    cardName: string;
    cardImage?: string;
    originalPrice?: number;
    helperData?: any;
    isMenuItemOrdered: boolean;
    initialQuantity: number;
    defaultPrice: number;
  };
}
const ThirdLevelCategoryCard: React.FC<Props> = ({ cardDetails }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const pusherCartData = useCartChannel();

  const { isOrderTimeClosed } = useAppSelector(
    (state) => state.tableStatusAndOrder
  );

  const [isCounterVisible, setCounterVisible] = useState(false);
  const [customizationModal, setCustomizationModal] = useState(false);
  const [repeatCustomizationModal, setRepeatCustomizationModal] =
    useState(false);
  const [toastMessage, setToastMessage] = useState<string | null>(null);
  const [
    repeatCustomizationModalActionType,
    setRepeatCustomizationModalActionType,
  ] = useState<"ADD" | "REMOVE">("ADD");
  const [menuQuantity, setMenuQuantity] = useState(cardDetails.initialQuantity);
  const [orderItemId, setOrderItemId] = useState<string | null>(null);
  const orderId = JSON.parse(sessionStorage.getItem("OrderId") || "{}");

  const [updateQuantity] = useUpdateOrderItemQuantityByProductIdMutation();
  const [addMenuItemToOrder] = useAddMenuItemToOrderByOrderIdMutation();

  const updateCardFromPusher = useCallback(
    (pusherData: any) => {
      if (!pusherData || pusherData.length === 0) {
        setMenuQuantity(0);
        setCounterVisible(false);
        return;
      }
      const productMasterId = cardDetails.helperData.productMaster?.id;
      pusherData.forEach((order: any) => {
        if (order.status === "NEW") {
          let totalQuantity: number = 0;
          order.orderItems.forEach((item: any) => {
            if (item.menuItemMaster?.productMaster?.id === productMasterId) {
              totalQuantity += item.quantity;
              setOrderItemId(item.id);
              item.status === "NEW"
                ? setCounterVisible(true)
                : setCounterVisible(false);
            }
          });
          setMenuQuantity(totalQuantity);
        } else {
          setMenuQuantity(0);
          setCounterVisible(false);
        }
      });
    },
    [cardDetails]
  );

  useEffect(() => {
    if (cardDetails.isMenuItemOrdered && cardDetails.initialQuantity > 0) {
      // setCounterVisible(true);
      setOrderItemId(cardDetails?.helperData?.orderItemHelperData?.id || null);
    }
  }, [cardDetails]);

  useEffect(() => {
    updateCardFromPusher(pusherCartData);
  }, [pusherCartData, updateCardFromPusher]);

  useEffect(() => {
    if (menuQuantity === 0) setCounterVisible(false);
  }, [menuQuantity]);

  const counterLoader = useAppSelector(
    (state) =>
      state.tab.counterLoader[
        cardDetails?.helperData?.menuItems[0]?.menuItemMasterId
      ] ?? false
  );

  const handleAddButtonClick = async () => {
    const servingSizeId =
      cardDetails?.helperData?.menuItems[0]?.menuItemMasterId;
    dispatch(handleCounterLoader({ itemId: servingSizeId, isLoading: true }));

    const customerDetails = JSON.parse(
      sessionStorage.getItem("CustomerDetails") || "{}"
    );

    let payload: any = {
      quantity: 1,
      menuItemMaster: { id: servingSizeId },
      price: cardDetails?.originalPrice,
    };

    if (customerDetails && Object.keys(customerDetails)) {
      payload.customerId = customerDetails.id;
    }

    try {
      const res = await addMenuItemToOrder({ orderId, menuItemsBody: payload });
      if (res?.data) {
        if (res.data.orderItemId) {
          setOrderItemId(res.data.orderItemId);
          setMenuQuantity(1);
          setCounterVisible(true);
        }
      } else {
        throw new Error(res?.error?.data?.message || "Failed to add item");
      }
    } catch (error: any) {
      console.error("Error during ADD operation: ", error);
      setToastMessage(error.message);
      if (
        error.message ===
        "Order is not in NEW status. Cannot add or update items."
      ) {
        navigate("/payment-in-progress-block");
      }
    } finally {
      dispatch(
        handleCounterLoader({ itemId: servingSizeId, isLoading: false })
      );
    }
  };

  const handleQuantityChange = async (updatedQuantity: number) => {
    if (!orderItemId) {
      console.error("OrderItemId is not available");
      return;
    }

    dispatch(
      handleCounterLoader({
        itemId: cardDetails?.helperData?.menuItems[0]?.menuItemMasterId,
        isLoading: true,
      })
    );

    if (
      (cardDetails?.helperData?.modifier ||
        cardDetails?.helperData?.instructions?.length > 0 ||
        cardDetails?.helperData?.menuItems?.length > 1) &&
      updatedQuantity >= 1
    ) {
      setRepeatCustomizationModalActionType(
        updatedQuantity > menuQuantity ? "ADD" : "REMOVE"
      );
      setRepeatCustomizationModal(true);
      dispatch(
        handleCounterLoader({
          itemId: cardDetails?.helperData?.menuItems[0]?.menuItemMasterId,
          isLoading: false,
        })
      );
    } else {
      try {
        const res = await updateQuantity({
          orderItemId: orderItemId,
          price: cardDetails?.originalPrice
            ? cardDetails?.originalPrice * updatedQuantity
            : 0,
          quantity: updatedQuantity,
        });

        if (res?.data || res?.data === null) {
          setMenuQuantity(updatedQuantity);
          if (updatedQuantity === 0) {
            setCounterVisible(false);
            setOrderItemId(null);
          }
        } else {
          throw new Error(
            res?.error?.data?.message || "Failed to update quantity"
          );
        }
      } catch (error: any) {
        console.error("Error updating quantity:", error);
        setToastMessage(error.message);
        if (
          error.message ===
          "Order is not in NEW status. Cannot add or update items."
        ) {
          navigate("/payment-in-progress-block");
        } else if (
          [
            "Order Item already confirmed",
            "Order group is currently in progress and cannot be modified.",
          ].includes(error.message.trim())
        ) {
          window.location.reload();
        }
      } finally {
        dispatch(
          handleCounterLoader({
            itemId: cardDetails?.helperData?.menuItems[0]?.menuItemMasterId,
            isLoading: false,
          })
        );
      }
    }
  };

  // Clear The Toast Message when the toast is removed
  const handleOnToastDismiss = () => {
    setToastMessage(null);
  };

  // Render the toast message if available
  const renderToast = () => {
    if (toastMessage) {
      return (
        <CustomToast
          message={toastMessage}
          handleOnToastDismiss={handleOnToastDismiss}
        />
      );
    }
    return null;
  };
  const preventScroll = useCallback((e: TouchEvent) => {
    e.preventDefault();
  }, []);

  // Prevent body scrolling when popover is open
  useEffect(() => {
    if (customizationModal || repeatCustomizationModal) {
      document.body.style.overflow = "hidden";
      document.body.style.touchAction = "none";
      // Prevent default touch move behavior except for modal content
      document.addEventListener("touchmove", preventScroll, { passive: false });
    } else {
      document.body.style.overflow = "";
      document.body.style.touchAction = "";
      document.addEventListener("touchmove", preventScroll);
    }

    return () => {
      document.body.style.overflow = "";
      document.body.style.touchAction = "";
      document.addEventListener("touchmove", preventScroll);
    };
  }, [customizationModal, repeatCustomizationModal, preventScroll]);

  const handleAddOverlay = () => {
    return (
      <div
        className="fixed inset-0 touch-none pointer-events-auto z-[999] w-full h-full"
        style={{
          WebkitTapHighlightColor: "transparent",
          // touchAction: "none",
          userSelect: "none",
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
        }}
        aria-hidden="true"
      />
    );
  };

  const handleCardWithoutImage = () => {
    return (
      <>
        <div className="flex flex-col gap-2">
          <div className="flex justify-between thirdLevelCategoryCard withoutImageCard w-full rounded-xl p-2 pr-0 ">
            <div className=" h-full flex flex-col pl-2 gap-4  justify-between">
              <div className="flex flex-col gap-1">
                <div className="cardText">
                  <span className="inline menuItemName">
                    {convertToCapitalizedString(cardDetails?.cardName)}
                  </span>
                </div>
                <div className="cardDescription line-clamp-2">
                  {cardDetails?.helperData?.description}
                </div>
              </div>
              <div className="flex flex-row items-center gap-6">
                {isCounterVisible ? (
                  <CounterButton
                    disable={isOrderTimeClosed}
                    buttonType="SMALL"
                    disableQuantityUpdate
                    initialQuantity={menuQuantity}
                    onQuantityChange={handleQuantityChange}
                    itemId={
                      cardDetails?.helperData?.menuItems[0]?.menuItemMasterId
                    }
                  />
                ) : (
                  <div className="flex flex-col items-center gap-0.85">
                    <button
                      disabled={isOrderTimeClosed || counterLoader}
                      onClick={() => {
                        if (
                          cardDetails?.helperData?.modifier ||
                          cardDetails?.helperData?.instructions?.length > 0 ||
                          cardDetails?.helperData?.menuItems?.length > 1 ||
                          cardDetails?.helperData?.recommendations
                        ) {
                          setCustomizationModal(true);
                        } else {
                          handleAddButtonClick();
                        }
                      }}
                      className={`addButton ${
                        isOrderTimeClosed || counterLoader ? "opacity-50" : ""
                      }`}
                    >
                      ADD
                    </button>
                    <div className="absolute -bottom-2 inset-x-0 h-0.5">
                      {counterLoader && <div className="dash" />}
                    </div>
                    {(cardDetails?.helperData?.modifier ||
                      cardDetails?.helperData?.instructions?.length > 0 ||
                      cardDetails?.helperData?.menuItems?.length > 1) && (
                      <span className="font-normal cardDescription mt-0.5">
                        Customisable
                      </span>
                    )}
                  </div>
                )}
                <div className="flex flex-col items-start gap-0.5">
                  {cardDetails?.helperData?.menuItems?.length > 1 && (
                    <div className="customizablePriceText">STARTS AT</div>
                  )}
                  <div className="cardText">
                    {cardDetails?.defaultPrice > 0 &&
                    cardDetails?.defaultPrice >
                      (cardDetails?.originalPrice || 0) &&
                    cardDetails?.defaultPrice !== null ? (
                      <span className="line-through strikeColor mr-[5px]">
                        {rupeeFormatter(cardDetails?.defaultPrice)}/-
                      </span>
                    ) : null}
                    <span>{rupeeFormatter(cardDetails?.originalPrice)}/-</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col justify-end">
              <div
                className="relative right-0 bottom-0"
                onClick={() => {
                  !isOrderTimeClosed && setCustomizationModal(true);
                }}
              >
                <MenuItemInfo
                  className={`iconColor ${
                    isOrderTimeClosed ? "opacity-70" : ""
                  }`}
                />
              </div>
            </div>
          </div>
          <div className="border-t mx-1 thirdLevelCategoryCardSeparator"></div>
        </div>
        {customizationModal && (
          <>
            {handleAddOverlay()}
            <MenuItemCustomizationModal
              productMasterId={cardDetails?.helperData?.productMaster?.id}
              onClose={() => {
                setCustomizationModal(false);
              }}
            />
          </>
        )}
        {repeatCustomizationModal && (
          <>
            <RepeatCustomizationModal
              actionType={repeatCustomizationModalActionType}
              productMasterId={cardDetails?.helperData?.productMaster?.id}
              isOpen={repeatCustomizationModal}
              onClose={() => setRepeatCustomizationModal(false)}
            />
          </>
        )}
        {renderToast()}
      </>
    );
  };

  const handleCompactCard = () => {
    return (
      <div>
        <div className="flex flex-col gap-2">
          <div className="flex justify-between thirdLevelCategoryCard compactCard w-full p-1 gap-2">
            <div className="h-full flex flex-col pl-2 justify-between">
              <div className="flex flex-col gap-1">
                <div className="cardText">
                  <span className="inline menuItemName">
                    {convertToCapitalizedString(cardDetails?.cardName)}
                  </span>
                </div>
                <div className="cardDescription line-clamp-2">
                  {cardDetails?.helperData?.description}
                </div>
              </div>
              <div className="flex flex-row items-center gap-6">
                {isCounterVisible ? (
                  <CounterButton
                    disable={isOrderTimeClosed}
                    buttonType="SMALL"
                    disableQuantityUpdate
                    initialQuantity={menuQuantity}
                    onQuantityChange={handleQuantityChange}
                    itemId={
                      cardDetails?.helperData?.menuItems[0]?.menuItemMasterId
                    }
                  />
                ) : (
                  <div className="flex flex-col items-center gap-0.85">
                    <button
                      disabled={isOrderTimeClosed || counterLoader}
                      onClick={() => {
                        if (
                          cardDetails?.helperData?.modifier ||
                          cardDetails?.helperData?.instructions?.length > 0 ||
                          cardDetails?.helperData?.menuItems?.length > 1 ||
                          cardDetails?.helperData?.recommendations
                        ) {
                          setCustomizationModal(true);
                        } else {
                          handleAddButtonClick();
                        }
                      }}
                      className={`addButton ${
                        isOrderTimeClosed || counterLoader ? "opacity-50" : ""
                      }`}
                    >
                      ADD
                    </button>
                    <div className="absolute -bottom-2 inset-x-0 h-0.5">
                      {counterLoader && <div className="dash" />}
                    </div>
                    {(cardDetails?.helperData?.modifier ||
                      cardDetails?.helperData?.instructions?.length > 0 ||
                      cardDetails?.helperData?.menuItems?.length > 1) && (
                      <span className="font-normal cardDescription mt-0.5">
                        Customisable
                      </span>
                    )}
                  </div>
                )}
                <div className="flex flex-col items-start gap-0.5">
                  {cardDetails?.helperData?.menuItems?.length > 1 && (
                    <div className="customizablePriceText">STARTS AT</div>
                  )}
                  <div className="cardText">
                    {cardDetails?.defaultPrice > 0 &&
                    cardDetails?.defaultPrice >
                      (cardDetails?.originalPrice || 0) &&
                    cardDetails?.defaultPrice !== null ? (
                      <span className="line-through strikeColor mr-[5px]">
                        {rupeeFormatter(cardDetails?.defaultPrice)}/-
                      </span>
                    ) : null}
                    {rupeeFormatter(cardDetails?.originalPrice)}/-
                  </div>
                </div>
              </div>
            </div>
            <div
              className="h-full flex flex-col justify-start items-center relative"
              onClick={() => {
                !isOrderTimeClosed && setCustomizationModal(true);
              }}
            >
              <img
                src={cardDetails.cardImage}
                alt={cardDetails.cardName}
                className="object-cover compactCardImage overflow-hidden"
              />
              <div className="absolute bottom-1 right-0">
                <MenuItemInfo
                  className={`iconColor ${
                    isOrderTimeClosed ? "opacity-70" : ""
                  }`}
                />
              </div>
            </div>
          </div>
          <div className="border-t mx-1 thirdLevelCategoryCardSeparator"></div>
        </div>
        {customizationModal && (
          <MenuItemCustomizationModal
            productMasterId={cardDetails?.helperData?.productMaster?.id}
            onClose={() => {
              setCustomizationModal(false);
            }}
          />
        )}
        {repeatCustomizationModal && (
          <RepeatCustomizationModal
            actionType={repeatCustomizationModalActionType}
            productMasterId={cardDetails?.helperData?.productMaster?.id}
            isOpen={repeatCustomizationModal}
            onClose={() => setRepeatCustomizationModal(false)}
          />
        )}
        {renderToast()}
      </div>
    );
  };

  if (
    cardDetails &&
    Object.keys(cardDetails).includes("cardImage") &&
    cardDetails?.cardImage !== undefined &&
    cardDetails?.cardImage !== null
  ) {
    return handleCompactCard();
  } else {
    return handleCardWithoutImage();
  }
};

export default ThirdLevelCategoryCard;

import { ReactComponent as WarningIcon } from "assets/images/field-error.svg";
import CountryDropdown from "components/CountryDropdown";
import CustomCheckBox from "components/CustomCheckBox";
import CustomToast from "components/CustomToast";
import FieldErrorMessage from "components/common/FieldErrorMessage";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { handleGlobalLoader } from "store/auth/authSlice";
import { useAppDispatch, useAppSelector } from "store/customHooks";
import { useCreateNewCustomerOrderMutation } from "store/orders/ordersAPISlice";
import {
  handleCustomerDetails,
  handleOrderDetails,
} from "store/tableStatusAndOrder/tableStatusAndOrderSlice";

interface FormValues {
  mobile: string;
  guests: string;
  terms: any;
  country: {
    code: string;
    name: string;
    numberLength: number;
    flag?: string;
    startDigits?: string;
  };
}

const CustomerDetailsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { initialAPISuccess, globalLoader } = useAppSelector(
    (state) => state.auth
  );

  const [formValues, setFormValues] = useState<FormValues>({
    mobile: "",
    guests: "",
    terms: false,
    country: {
      code: "+91",
      name: "India",
      numberLength: 10,
      flag: "IN",
      startDigits: "6-9",
    },
  });
  // Add this function to handle country changes
  const handleCountryChange = (country: FormValues["country"]) => {
    setFormValues((prev) => ({
      ...prev,
      country,
      mobile: "", // Reset mobile number when country changes
    }));
    setErrors((prev) => ({ ...prev, mobile: "" })); // Clear mobile validation errors
  };
  const [errors, setErrors] = useState<Partial<FormValues>>({});
  const [failedAPIError, setFailedAPIError] = useState({
    isActive: false,
    errMessage: "",
  });
  const [submitButtonClicked, setSubmitButtonClicked] = useState(false);

  const checkIn: any = sessionStorage.getItem("CheckIn");
  const ids = sessionStorage.getItem("Ids");
  const parsedIds = ids && JSON.parse(ids);
  const branchDetails = sessionStorage.getItem("Branch");
  const parsedBranchDetails = branchDetails && JSON.parse(branchDetails);
  const tenantDetails = sessionStorage.getItem("CustomerApp-Tenant");
  const parsedTenantDetails = tenantDetails && JSON.parse(tenantDetails);

  const [createCustomer] = useCreateNewCustomerOrderMutation();

  // useEffect(() => {
  //   if (!checkIn) {
  //     window.location.href = "/";
  //   }
  // }, [checkIn]);

  // Reset error toast after 3 seconds
  useEffect(() => {
    if (failedAPIError.isActive) {
      const timer = setTimeout(() => {
        setFailedAPIError({ isActive: false, errMessage: "" });
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [failedAPIError.isActive]);

  const renderError = () => {
    if (failedAPIError?.isActive) {
      return <CustomToast message={failedAPIError?.errMessage} />;
    }
    return null;
  };

  const validateForm = (): boolean => {
    const newErrors: Partial<FormValues> = {};
    // Dynamic mobile number validation based on country
    const { numberLength, name, startDigits } = formValues.country;

    // Update mobile validation to use country's number length
    let mobileRegex: RegExp;
    if (startDigits) {
      // If country has specific starting digit requirements
      const startDigitPattern = startDigits.includes("-")
        ? `[${startDigits}]`
        : `[${startDigits.split("").join("")}]`;
      mobileRegex = new RegExp(
        `^${startDigitPattern}\\d{${numberLength - 1}}$`
      );
    } else {
      // Default pattern if no specific starting digits are specified
      mobileRegex = new RegExp(`^\\d{${numberLength}}$`);
    }

    if (!mobileRegex.test(formValues.mobile)) {
      newErrors.mobile = startDigits
        ? `Phone number must be ${numberLength} digits and start with ${startDigits} for ${name}`
        : `Phone number must be ${numberLength} digits for ${name}`;
    }

    if (checkIn === "NEW") {
      if (!/^[1-9]\d{0,2}$/.test(formValues.guests)) {
        newErrors.guests =
          "Number of guests must be between 1 and 6 and cannot start with 0";
      }
    }

    if (!formValues.terms) {
      newErrors.terms = "Required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      dispatch(handleGlobalLoader(true));

      sessionStorage.setItem(
        "CustomerDetails",
        JSON.stringify({
          mobile: formValues.mobile,
        })
      );
      dispatch(handleCustomerDetails(formValues));

      let payload: any = {
        phoneNo: `${formValues.country.code}${formValues.mobile}`,
        noOfGuest: formValues.guests || 0,
        tableId: parsedIds?.tableId,
        branchId: parsedIds?.branchId ?? parsedBranchDetails?.branchId,
      };
      if (
        localStorage?.getItem("deviceId") !== undefined &&
        localStorage?.getItem("deviceId") !== null &&
        localStorage?.getItem("deviceId") !== "undefined" &&
        localStorage?.getItem("deviceId") !== "null"
      ) {
        payload = {
          ...payload,
          deviceId: localStorage?.getItem("deviceId"),
        };
      }

      createCustomer(payload).then((res: any) => {
        if (res?.data) {
          if (res?.data?.orderId) {
            dispatch(handleOrderDetails({ id: res?.data?.orderId }));
            sessionStorage.setItem("OrderId", res?.data?.orderId);
          }
          if (res?.data?.primaryCustomerName || res?.data?.customerDetails) {
            dispatch(
              handleCustomerDetails({
                // ...payload,
                tableCode: payload?.tableId,
                guests: payload?.noOfGuest,
                mobile: payload?.phoneNo,
                primaryName: res?.data?.primaryCustomerName || "",
                name: res?.data?.customerDetails
                  ? res?.data?.customerDetails?.name
                  : "",
              })
            );
            if (res?.data?.customerDetails) {
              const customerDetailsPayload = {
                id: res?.data?.customerDetails?.id,
                name: res?.data?.customerDetails?.name,
                mobile: payload?.phoneNo,
                primaryName: res?.data?.primaryCustomerName || "",
              };
              sessionStorage.setItem(
                "CustomerDetails",
                JSON.stringify(customerDetailsPayload)
              );
              res?.data?.customerDetails?.deviceId &&
                localStorage.setItem(
                  "deviceId",
                  res?.data?.customerDetails?.deviceId
                );
            }
          } else {
            dispatch(
              handleCustomerDetails({
                tableCode: payload?.tableId,
                guests: payload?.noOfGuest,
                mobile: payload?.phoneNo,
              })
            );
          }

          if (
            res?.data?.customerAlreadyMapped &&
            res?.data?.orderId &&
            Object.keys(res?.data?.customerDetails || {})?.length
          ) {
            navigate("/home", { state: { preventBackwardNavigation: true } });
          } else if (res?.data?.isOtpNeeded) {
            navigate("/verify-otp", {
              state: { preventBackwardNavigation: true },
            });
          } else if (res?.data?.isNewNumber) {
            navigate("/enter-name", {
              state: { preventBackwardNavigation: true },
            });
          } else if (res?.data?.isTablePinNeeded) {
            navigate("/verify-pin", {
              state: { preventBackwardNavigation: true },
            });
          } else {
            res?.data?.orderId
              ? navigate("/home", {
                state: { preventBackwardNavigation: true },
              })
              : setFailedAPIError({
                isActive: true,
                errMessage:
                  "Oops, We were not able to associate the number with the table. Please scan again",
              });
          }
          dispatch(handleGlobalLoader(false));
        } else {
          const errMessage =
            res?.error?.data?.message ??
            res?.error?.error ??
            res?.error?.data?.error ??
            "Something went wrong. Please try again!";
          setFailedAPIError({ isActive: true, errMessage: errMessage });
          dispatch(handleGlobalLoader(false));
        }
      });
    }
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    fieldName: keyof FormValues
  ) => {
    let value = event.target.value;
    let regex: RegExp | undefined;
    let maxLength: number | undefined;

    if (fieldName === "mobile") {
      // Allow any digit for international numbers, but respect max length
      const maxLength = formValues.country.numberLength;
      if (/^\d*$/.test(value) && value.length <= maxLength) {
        setFormValues((prev) => ({ ...prev, [fieldName]: value }));
      }
    } else if (fieldName === "guests") {
      // regex = /^[1-9]\d{0,2}$/;
      // maxLength = 3;
      if (/^[1-9]\d{0,1}$/.test(value) && parseInt(value) <= 6) {
        setFormValues((prev) => ({ ...prev, [fieldName]: value }));
      }
    }

    if (regex && maxLength) {
      if (regex.test(value) && value.length <= maxLength) {
        setFormValues({ ...formValues, [fieldName]: value });
      } else if (value === "") {
        setFormValues({ ...formValues, [fieldName]: "" });
      }
    } else {
      setFormValues({ ...formValues, [fieldName]: value });
    }
  };

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
    fieldName: keyof FormValues
  ) => {
    let regex: RegExp | undefined;
    let maxLength: number | undefined;

    if (fieldName === "mobile") {
      regex = /^\d*$/;
      maxLength = 10;
    } else if (fieldName === "guests") {
      regex = /^\d*$/;
      maxLength = 3;
    }

    const isSpecialKey = [
      "Backspace",
      "Delete",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
    ].includes(event.key);

    const isValidKey = regex ? regex.test(event.key) || isSpecialKey : true;
    const isLengthValid =
      !maxLength ||
      (event.currentTarget as HTMLInputElement).value.length < maxLength ||
      isSpecialKey;

    if (!isValidKey || !isLengthValid) {
      event.preventDefault();
    }
  };

  const renderErrorMessage = (field: keyof FormValues) => {
    return submitButtonClicked && errors[field] ? (
      <FieldErrorMessage message={errors[field] as string} />
    ) : null;
  };

  const handleGuestSelection = (num: number) => {
    setFormValues({ ...formValues, guests: num.toString() });
  };

  const handleConditionsApplyText = () => {
    if (parsedTenantDetails?.tenantName?.replace(/[^a-zA-Z]/g, "")?.toLowerCase()?.includes("bob")) {
      return (<>
        <span className="ml-2 conditionSubText">
          I agree to pay the{" "}
          <span
            className="font-bold"
            style={{ textDecoration: "underline" }}
            onClick={() => navigate("/terms/service")}
          >
            Service Charge
          </span>{" "}
          at all times & the{" "}
          <span
            className="font-bold"
          >
            Surcharge (Fri/Sat/Sun 7PM onwards)
          </span>{" "}
          at this outlet, and accept the
          <span
            className="font-bold"
            style={{ textDecoration: "underline" }}
            onClick={() => navigate("/terms/conditions")}
          >
            Terms & Conditions
          </span>
        </span>
      </>);
    } else {
      return (<>
        <span className="ml-2 conditionSubText">
          I agree to pay the{" "}
          <span
            className="font-bold"
            style={{ textDecoration: "underline" }}
            onClick={() => navigate("/terms/service")}
          >
            Service Charge
          </span>{" "}
          at this outlet and to the{" "}
          <span
            className="font-bold"
            style={{ textDecoration: "underline" }}
            onClick={() => navigate("/terms/conditions")}
          >
            Terms & Conditions
          </span>
        </span>
      </>);
    }
  }

  return (
    <>
      {initialAPISuccess && (checkIn === "NEW" || checkIn === "JOIN") ? (
        <div className="flex-1 initialLoginPage">
          <form onSubmit={handleSubmit}>
            <div className="h-full flex flex-col justify-between customerDetailsPage">
              <div className="px-4 flex flex-col gap-3">
                <div className="customerDetailsFormContainer flex flex-col gap-4">
                  <div className="flex flex-col gap-1">
                    <div>
                      <div className="pt-5 pb-3">
                        Enter your Phone Number to start
                      </div>

                      <div
                        className={`flex items-center border rounded normalInputBorder ${errors.mobile ? "errorInputBorder" : ""
                          }`}
                      >
                        <CountryDropdown
                          selectedCountry={formValues.country}
                          onCountryChange={handleCountryChange}
                          error={!!errors.mobile}
                        />
                        <input
                          type="tel"
                          pattern="[0-9]*"
                          inputMode="numeric"
                          placeholder={`Enter ${formValues.country.numberLength} digit mobile number`}
                          name="mobile"
                          value={formValues.mobile}
                          maxLength={formValues.country.numberLength}
                          onChange={(event) => {
                            // Only allow numeric values
                            const numericValue = event.target.value.replace(
                              /[^0-9]/g,
                              ""
                            );
                            const newEvent = {
                              ...event,
                              target: {
                                ...event.target,
                                value: numericValue,
                              },
                            };
                            handleChange(newEvent, "mobile");
                          }}
                          onKeyPress={(event) => {
                            // Prevent non-numeric key presses
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          className="box-border px-2 inputField w-full"
                        />
                      </div>
                      {renderErrorMessage("mobile")}
                    </div>
                    {checkIn === "NEW" && (
                      <div>
                        <p className="pt-4 pb-3">How many of you today?</p>
                        <div className="flex">
                          {[1, 2, 3, 4, 5, 6].map((num) => (
                            <button
                              key={num}
                              className={`flex-1 py-2 border-2 ${formValues.guests === num.toString()
                                ? "selectedNumberOfGuests"
                                : "unSelectedNumberOfGuests"
                                }`}
                              onClick={() => handleGuestSelection(num)}
                              type="button"
                            >
                              {num}
                            </button>
                          ))}
                        </div>
                        {renderErrorMessage("guests")}
                      </div>
                    )}
                  </div>
                  <div>
                    <div className="pt-[15px] pb-[5px] flex gap-3 items-center">
                      <CustomCheckBox
                        disabled={false}
                        type="CHECKBOX"
                        checked={formValues.terms}
                        onChange={() =>
                          setFormValues({
                            ...formValues,
                            terms: !formValues.terms,
                          })
                        }
                      />
                      {handleConditionsApplyText()}
                    </div>
                    {renderErrorMessage("terms")}
                  </div>
                </div>
                <div
                  className={`mt-3 ${checkIn === "JOIN" ? "h-[25%]" : "h-1/6"}`}
                >
                  <div className="flex flex-col justify-center items-center mt-1">
                    <button
                      type="submit"
                      className="customerFormSubmitButton w-full text-center"
                      onClick={() => setSubmitButtonClicked(true)}
                    >
                      {checkIn === "NEW" ? "Get Started" : "Join"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          {renderError()}
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center flex-1 mb-5 px-2">
          {initialAPISuccess && checkIn === "CUST_ASSOC_NOT_ASSIGNED" && (
            <div className="text-center flex flex-col items-center justify-center h-full notServiceableText">
              <WarningIcon
                width={32}
                height={42}
                className="mb-[10px] warningIcon iconColor"
              />
              <div>Sorry! This table is currently not serviceable.</div>
            </div>
          )}
          {initialAPISuccess && checkIn === "CHECKOUT" && (
            <div className="text-center flex flex-col items-center justify-center h-full notServiceableText">
              <WarningIcon
                width={32}
                height={42}
                className="mb-[10px] warningIcon iconColor"
              />
              <div>
                This table is currently being checked out, Please wait to start
                a new check.
              </div>
            </div>
          )}
          {!initialAPISuccess && !globalLoader && (
            <div className="text-center flex flex-col items-center justify-center h-full notServiceableText">
              <WarningIcon
                width={32}
                height={42}
                className="mb-[10px] warningIcon iconColor"
              />
              <div>
                We were unable to identify the table. Kindly scan again.
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default CustomerDetailsPage;

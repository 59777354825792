import SecondLevelCategoryAccordion from "components/category/SecondLevelCategoryAccordion";
import FloatingMenuListButton from "components/FloatingMenuListButton";
import ToggleItemSection from "components/toggle-item-section";
import { useOrder } from "context/order/OrderContext";
import { handleAccordionData } from "components/category/accordionUtils";
import React, { useEffect, useRef, useState } from "react";
import { IObject } from "types";

interface Props {
  categoryTabData: IObject;
  floatingButtonData: { name: string; helperData?: any }[];
}

const MenuListingTabContent: React.FC<Props> = ({
  categoryTabData,
  floatingButtonData,
}) => {
  const { orderData, orderIsSuccess } = useOrder();

  const [finalAccordionData, setFinalAccordionData] = useState<any>([]);
  const [showFloatingButton, setShowFloatingButton] = useState<boolean>(true);
  const lastScrollTop = useRef(0);

  useEffect(() => {
    // Scroll to the top on load
    window.scrollTo(0, 0);
  }, [categoryTabData]);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop =
        window.scrollY || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const bottomThreshold = 0;

      // Check if scrolling up from bottom
      if (currentScrollTop < lastScrollTop.current) {
        setShowFloatingButton(true);
      } else {
        // Only hide when actually at bottom
        const isAtBottom =
          documentHeight - (currentScrollTop + windowHeight) <= bottomThreshold;
        setShowFloatingButton(!isAtBottom);
      }

      lastScrollTop.current = currentScrollTop;
    };

    // Set initial state to true (show button)
    setShowFloatingButton(true);

    // Add scroll event listener without throttling for immediate response
    window.addEventListener("scroll", handleScroll, { passive: true });

    // Clean up
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (
      categoryTabData &&
      Object.keys(categoryTabData).length > 0 &&
      orderIsSuccess
    ) {
      const accordionData = handleAccordionData([categoryTabData], orderData);
      setFinalAccordionData(accordionData);
    } else {
      setFinalAccordionData([]); // Clear if no categoryTabData
    }
  }, [orderIsSuccess, orderData, categoryTabData]);

  return (
    <>
      <div>
        <ToggleItemSection />
        <>
          {finalAccordionData &&
            finalAccordionData.length > 0 &&
            finalAccordionData.map((data: any, index: number) => (
              <div key={index} className="my-[10px]">
                <SecondLevelCategoryAccordion
                  index={index}
                  accordionHeader={data.accordionHeader}
                  accordionHeaderCount={data.accordionHeaderCount}
                  isMainAccordionOpen={data.isMainAccordionOpen}
                  mainAccordionBody={data.mainAccordionBody}
                  isNestedAccordion={data.isNestedAccordion}
                  nestedAccordionBody={data.nestedAccordionBody}
                  helperData={data?.helperData}
                />
              </div>
            ))}
          {floatingButtonData &&
            floatingButtonData.length > 0 &&
            showFloatingButton && (
              <FloatingMenuListButton menuListData={floatingButtonData} />
            )}
        </>
      </div>
    </>
  );
};

export default MenuListingTabContent;
